.home-indent{
    /* margin-left:45px; */
    display: inline-block;
}
.home-indent-two{
    margin-left:30px
}
.home-indent:last-child{
    margin-left:35px;
}
.home-main-header{
    margin-bottom: 0;
}
.home-main-header span{
    display: block;
}
.home-main-image{
    aspect-ratio: 1/1;
    position: absolute;
    top:0px;
    right:30px;
    border-radius: 100%;
    z-index: 10;
    overflow: hidden;
    background-image: url(../../images/pfp5.jpg);
    background-size: cover;
    min-width: 220px;
    max-width: 500px !important;
    width:35%;
    box-shadow: 0px 0px 30px -17px rgba(152,255,152,1);
    opacity: 0;
    
    /* animation-iteration-count: infinite; */
    /* animation-delay: 5s; */
}
.home-main-image-2{
    opacity:1;
    /* width:; */
    /* height:; */
    aspect-ratio: 1/1;
    position: absolute;
    top:0px;
    right:30px;
    border-radius: 100%;
    z-index: 10;
    overflow: hidden;
    background-image: url(../../images/pfp5.jpg);
    background-size: cover;
    min-width: 220px;
    max-width: 500px !important;
    width:35%;
    box-shadow: 0px 0px 30px -17px rgba(152,255,152,1);
}
.home-main-image.main-image-fadein {
    opacity:1;
    animation: fadeinout 1s ease-in-out;
}
.main-image-fadeout{
    animation: fadeOutRight 1s ease-in-out;
}
@keyframes fadeOutRight {
    0% {
        opacity: 1;
        transform: translateX(-300px);
    }
    100%{
        opacity: 0;
        /* transform: translateX(-300px); */
    }
}
@keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(300px);
    }
    100%{
        opacity: 1;
        /* transform: translateX(-300px); */
    }
  }
.home-bottom-conatiner{
    margin-bottom: -10vw;
}
p{
   position: relative;
    z-index: 2;  
}
.home-bottom-conatiner p, .home-second-conatiner p{
    text-indent: 50px;
   
}

@media (max-width: 960px) {
    .home-main-image{
        left: 0;
        top:calc(100% - 100px);
        margin:0 auto;
    }
    .home-first-content{
        padding-bottom: 140px;
    }
}