

.certificates{
    margin: 55px 2% 0px;
    padding-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: white;
}

// .lambda-cert{
//     width: 260px;
//     height:260px;
//     overflow: hidden;
    
//     :hover{
//         background-color: white;
//     }
// } 
#wave-cert{
    transform: rotate((180deg));
    margin-top:-2px;
}
.cert-list {
    display: flex;
    justify-content: space-around;
}
.cert-list .image{
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
    width: 260px;
    height: 260px;
    border-radius: 100%;
    overflow: hidden;
    
}
.cert-list .image div{
    width: 260px;
    height: 260px;
    transition: 0.25s;
}
.cert-list .image div:hover{
    cursor: pointer;
    background: rgba(50,50,50, .175);
}
.cert-list .image div:active{
    background: rgba(0,0,0, .475);
}
/* Social buttons */
.cert-list .image.lambda-cert{
    background-image: url('../../images/old/certs/full-stack-web-development.png');
}

@media (max-width: 900px){
    .cert-list .image{
        background-size: contain;
        width: 92vw;
        height: 92vw;
        border-radius: 120px;
        overflow: hidden;
    }
    .cert-list .image div{
        width: 92vw;
        height: 92vw;
}
}
